import React from 'react';
import { Card, CardImg, CardBody, CardTitle, CardText, Button } from 'reactstrap';
import video from '../images/Video.mp4';
import massageknox from '../images/massageknox.png'
import spaapp from '../images/spaapp.png';
import weatherapp from '../images/weatherapp.png';

function Projects(props) {

    return (
        <div className='bg-dark'>
            <div className="container pb-5">
                <div className='row'>
                    <div className='col backgroundGray d-flex align-items-start mt-5 pb-5'>
                        <h1><u>Projects</u></h1>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-6 pb-5 backgroundGray d-flex justify-content-center'>
                        <Card style={{ width: '28rem' }}>
                            <video src={video} width="100%" height="400" controls />
                            <CardBody style={{ backgroundColor: 'rgb(200, 220, 210)' }}>
                                <CardTitle>
                                    <p className='h1'>Massage Knox App</p>
                                </CardTitle>
                                <CardText>
                                    The Massage Knox mobile app is my first major project. It is a rewards app that keeps track of how many times a client has visited the massage business using the camera on the phone to scan a QR code. They earn a stamp with each visit and then redeem them to receive a discount. It also receives push notifications to let clients know about last minute openings.
                                </CardText>
                                <CardText>
                                    I started it while in the NuCamp Bootcamp. I built the front end with React Native and the back end with Express.
                                    It was designed for and is currently being used by a local business. It is available on both of the mobile app stores.
                                </CardText>
                                <CardText>
                                    Links to code:
                                </CardText>
                                <Button className="mr-5" varient="secondary" href="https://github.com/toddgann8006/Massage-Knox-App-IOS">Front End</Button>
                                <Button className="ml-5" varient="secondary" href="https://github.com/toddgann8006/Massage-Knox-Server">Back End</Button>
                            </CardBody>
                        </Card>
                    </div>
                    <div className='col-lg-6 pb-5 backgroundGray d-flex justify-content-center'>
                        <Card style={{ width: '28rem' }}>
                            <CardImg src={massageknox} alt='massage knox home screen'
                                top width="100%" />
                            <CardBody style={{ backgroundColor: 'rgb(200, 220, 210)' }}>
                                <CardTitle>
                                    <p className='h1'>Massage Knox Wordpress Site</p>
                                </CardTitle>
                                <CardText>
                                    This is a Wordpress site I created for a local business. The site uses custom CSS to make it responsive across all mobile devices. The home page includes an About section, Google Reviews, and Google Map. Other pages include a list of services offered, options for booking appointments, purchasing gift cards, and clinic policies.
                                </CardText>
                                <CardText>
                                    Links:
                                </CardText>
                                <Button className="mr-5" varient="secondary" href="https://github.com/toddgann8006/Massage-Knox-Wordpress">Code</Button>
                                <Button className="ml-5" varient="secondary" href="https://massageknox.com">Live Site</Button>
                            </CardBody>
                        </Card>
                    </div>
                    <div className='col-lg-6 pb-5 backgroundGray d-flex justify-content-center'>
                        <Card style={{ width: '28rem' }}>
                            <CardImg src={spaapp} alt='spa app home screen'
                                top width="100%" />
                            <CardBody style={{ backgroundColor: 'rgb(200, 220, 210)' }}>
                                <CardTitle>
                                    <p className='h1'>Healing Touch Spa</p>
                                </CardTitle>
                                <CardText>
                                    This is a spa website I designed while in the NuCamp Bootcamp. It was made using Bootstrap. It is currently deployed on Netlify. The site has multiple pages listing specific services available for clients. It also contains an about page and a contact page.
                                </CardText>
                                <CardText>
                                    Links:
                                </CardText>
                                <Button className="mr-5" varient="secondary" href="https://github.com/toddgann8006/Bootstrap-Spa-Project">Code</Button>
                                <Button className="ml-5" varient="secondary" href="https://healingtouch.netlify.app/">Live Site</Button>
                            </CardBody>
                        </Card>
                    </div>
                    <div className='col-lg-6 pb-5 backgroundGray d-flex justify-content-center'>
                        <Card style={{ width: '28rem' }}>
                            <CardImg src={weatherapp} alt='react weather app home screen'
                                top width="100%" />
                            <CardBody style={{ backgroundColor: 'rgb(200, 220, 210)' }}>
                                <CardTitle>
                                    <p className='h1'>React Weather App</p>
                                </CardTitle>
                                <CardText>
                                    This is a weather app I created using React for the front end and Express for the back end. After the user enters the zip code, it returns the weather for the city they have chosen as well as changes the background image based on the weather. The weather is retrieved from the OpenWeather API.
                                </CardText>
                                <CardText>
                                    Link to code:
                                </CardText>
                                <Button varient="secondary" className="mr-5" href="https://github.com/toddgann8006/react-weather-app">Front End</Button>
                                <Button varient="secondary" className="ml-5" href="https://github.com/toddgann8006/express-weather-server">Back End</Button>
                                <CardText className="mt-3 mb-3">Link to site:</CardText>
                                <Button varient="secondary" href="https://weatherappbytodd.netlify.app/">Live Site</Button>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Projects; 