import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Footer(props) {
    return (
        <footer className="bg-light">
            <div className="container">
                <div className="row pt-4 pb-3">
                    <div className="col-4">
                        <a className="text-decoration-none text-dark" href="https://github.com/toddgann8006">
                            <FontAwesomeIcon icon={faGithub} size="xl" />
                        </a>
                    </div>
                    <div className="col-4">
                        <a className="text-decoration-none text-dark" href="https://www.linkedin.com/in/todd-gann8006/">
                            <FontAwesomeIcon icon={faLinkedinIn} size="xl" />
                        </a>
                    </div>
                    <div className="col-4">
                        <a className="text-decoration-none text-dark" href="mailto:toddgann8006@comcast.net">
                            <FontAwesomeIcon icon={faEnvelope} size="xl" />
                        </a>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col d-flex justify-content-center">
                        <p>&copy; Todd Gann, 2022</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
